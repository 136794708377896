.navbar {
    padding: 1%;
    width: 98%;

    position: fixed;
    display: flex;
    justify-content: space-evenly;
    
    border-bottom: 2px solid var(--color-shadow);
    border-radius: 0 0 16px 16px;
    box-shadow: 0 10px 5px -5px var(--color-shadow-dim);

    background-color: var(--color-2);
    color: var(--color-shadow);
}

.nav-button {
    flex: 1 2 auto;
}

.nav-info {
    flex: 2 1 auto;
    text-align: center;
}

.nav-title {
    font-size: 28px;
}

.nav-title span {
    font-size: 22px;
}

.nav-description {
    font-style: italic;
}

.nav-links {
    display: none;

    margin: 0;
    padding: 0;
    width: 200px;
    height: 100%;

    z-index: 100;
    position: fixed;
    overflow: auto;

    border-right: 1px solid var(--color-shadow);
    border-radius: 0 10px 10px 0;
    box-shadow: 10px 0 5px -5px var(--color-shadow-dim);
    
    background-color: var(--color-3);

    left: -200px;
    transition: left 2s;
}

.nav-links.open {
    display: block;

    left: 0;
    transition: left 2s;
}

.nav-links a {
    display: block;
    padding: 16px;
    text-decoration: none;

    color: var(--color-shadow);
}

.nav-links a.active {
    background-color: var(--color-1);
}

.nav-links a:hover:not(.active) {
    background-color: var(--color-1-dim);
}

.page-container {
    position: relative;
    z-index: -1;
}

.panel {
    width: 90%;
    
    display: flex;
    justify-content: center;
    flex-flow: column;

    text-align: center;
}

.image-container {
    width: 100%;
    height: 400px;
}