:root {
    --color-1: #EE8;
    --color-1-dim: #EE84;

    --color-2: #ADF;
    --color-2-dim: #ADF6;

    --color-3: #AAA;
    --color-3-dim: #AAA5;

    --color-shadow: #000;
    --color-shadow-dim: #0007;
}